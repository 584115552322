let a = [
  {
    "Speciality": "Allergy and Immunology",
    "Sub Speciality": "Allergy and Immunology",
    "Classification": "Allergy Specialist"
  },
  {
    "Speciality": "Anesthesiology and Pain Management",
    "Sub Speciality": "Anesthesiology",
    "Classification": "Anaesthetist, anesthetist"
  },
  {
    "Speciality": "Anesthesiology and Pain Management",
    "Sub Speciality": "Critical Care Medicine",
    "Classification": "ITU specialist, Intensivist"
  },
  {
    "Speciality": "Anesthesiology and Pain Management",
    "Sub Speciality": "Obstetric Anesthesiology"
  },
  {
    "Speciality": "Anesthesiology and Pain Management",
    "Sub Speciality": "Pain Medicine",
    "Classification": "Pain Specialist"
  },
  {
    "Speciality": "Anesthesiology and Pain Management",
    "Sub Speciality": "Pediatric Anesthesiology",
    "Classification": "Childrens Anaesthetist"
  },
  {
    "Speciality": "Ayurved and Panchakarma",
    "Sub Speciality": "Ayurved and Panchakarma",
    "Classification": "Ayuvedic specialist"
  },
  {
    "Speciality": "Cardiology",
    "Sub Speciality": "Cardiology",
    "Classification": "Heart specialist"
  },
  {
    "Speciality": "Cardiology",
    "Sub Speciality": "Fetal Cardiology",
    "Classification": "Newborn heart specialist"
  },
  {
    "Speciality": "Cardiology",
    "Sub Speciality": "Cardiac Physiologist"
  },
  {
    "Speciality": "Cardiology",
    "Sub Speciality": "Interventional Cardiology"
  },
  {
    "Speciality": "Dematology",
    "Sub Speciality": "Dermatology",
    "Classification": "Skin specialist"
  },
  {
    "Speciality": "Dematology",
    "Sub Speciality": "Pediatric Dermatology",
    "Classification": "Dermatologist"
  },
  {
    "Speciality": "Dematology",
    "Sub Speciality": "Procedural Dermatology",
    "Classification": "Dermatologist"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Abdominal Radiology",
    "Classification": "Radiologist"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Breast Imaging"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Cardiothoracic Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Cardiovascular Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Chest Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Emergency Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Endovascular Surgical Neuroradiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Gastrointestinal Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Genitourinary Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Head and Neck Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Interventional Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Musculoskeletal Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Neuroradiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Nuclear Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Pediatric Radiology"
  },
  {
    "Speciality": "Diagnostics Radiology",
    "Sub Speciality": "Vascular and Interventional Radiology"
  },
  {
    "Speciality": "Diet & Nutrition",
    "Sub Speciality": "Diet & Nutrition",
    "Classification": "Dietician"
  },
  {
    "Speciality": "Ear Nose Throat",
    "Sub Speciality": "Ear Nose Throat",
    "Classification": "ENT doctor, otologist, laryngologist, throat specialist"
  },
  {
    "Speciality": "Emergency Medicine",
    "Sub Speciality": "Emergency Medicine",
    "Classification": "A&E Specialist"
  },
  {
    "Speciality": "Emergency Medicine",
    "Sub Speciality": "Pediatric Emergency Medicine "
  },
  {
    "Speciality": "Emergency Medicine",
    "Sub Speciality": "Undersea and Hyperbaric Medicine"
  },
  {
    "Speciality": "Endocrinology, Metabolism & Diabetes",
    "Sub Speciality": "Endocrinology"
  },
  {
    "Speciality": "Endocrinology, Metabolism & Diabetes",
    "Sub Speciality": "Diabetology",
    "Classification": "Diabetes specialist"
  },
  {
    "Speciality": "Gastroenterology",
    "Sub Speciality": "Gastroenterology",
    "Classification": "GI specialist, Gastro"
  },
  {
    "Speciality": "Geriatrics",
    "Sub Speciality": "Geriatrics",
    "Classification": "Old people specialist"
  },
  {
    "Speciality": "Hematology",
    "Sub Speciality": "Hematology",
    "Classification": "Blood specialist"
  },
  {
    "Speciality": "Homeopathy",
    "Sub Speciality": "Homeopathy"
  },
  {
    "Speciality": "Infectious Disease",
    "Sub Speciality": "Infectious Disease"
  },
  {
    "Speciality": "Intensive Care Medicine",
    "Sub Speciality": "Intensive Care Medicine"
  },
  {
    "Speciality": "Intensive Care Medicine",
    "Sub Speciality": "Paediatric Intensive Care Medicine"
  },
  {
    "Speciality": "Internal Medicine",
    "Sub Speciality": "Internal Medicine"
  },
  {
    "Speciality": "Internal Medicine",
    "Sub Speciality": "Internal Medicine-Pediatrics"
  },
  {
    "Speciality": "Interventional Radiology",
    "Sub Speciality": "Interventional Radiology"
  },
  {
    "Speciality": "Medical Genetics",
    "Sub Speciality": "Biochemical Genetics"
  },
  {
    "Speciality": "Medical Genetics",
    "Sub Speciality": "Clinical Cytogenetics"
  },
  {
    "Speciality": "Medical Genetics",
    "Sub Speciality": "Clinical Genetics"
  },
  {
    "Speciality": "Medical Genetics",
    "Sub Speciality": "Molecular Genetic Pathology"
  },
  {
    "Speciality": "Nephrology",
    "Sub Speciality": "Nephrology",
    "Classification": "Kidney specialist"
  },
  {
    "Speciality": "Nephrology",
    "Sub Speciality": "Pediatric Nephrology",
    "Classification": "Childrens kidney specialist"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Brain Injury Medicine",
    "Classification": "Stroke specialist"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Clinical Neurophysiology"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Endovascular Surgical Neuroradiology"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Neurodevelopmental Disabilities"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Neurology"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Neuromuscular Medicine"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Pain Medicine",
    "Classification": "Pain Specialist"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Pediatric Neurology"
  },
  {
    "Speciality": "Neurology",
    "Sub Speciality": "Vascular Neurology"
  },
  {
    "Speciality": "Nuclear Medicine",
    "Sub Speciality": "Nuclear Medicine"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Female Pelvic Medicine and Reconstructive Surgery "
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Gynaecology"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Maternal-Fetal Medicine"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Obstetrics"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Obstetrics and Gynaecology"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Reproductive Endocrinology and Infertility"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Gynaecological Oncology"
  },
  {
    "Speciality": "Obstetrics & Gynaecology",
    "Sub Speciality": "Urogynaecology"
  },
  {
    "Speciality": "Occupational Medicine",
    "Sub Speciality": "Occupational Medicine"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Gynecologic Oncology"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Medical Oncology"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Ocular Oncology"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Radiation Oncology"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Surgical Oncology"
  },
  {
    "Speciality": "Oncology",
    "Sub Speciality": "Urologic Oncology"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Anterior Segment/Cornea ophthalmology"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Glaucoma Ophthalmology"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Neuro-Ophthalmology"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Ophthalmic Plastic & Reconstructive Surgery"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Ophthalmology"
  },
  {
    "Speciality": "Ophthalmology",
    "Sub Speciality": "Strabismus/Pediatric Ophthalmology"
  },
  {
    "Speciality": "Oral/Dental Surgery",
    "Sub Speciality": "Oral/Dental Surgery"
  },
  {
    "Speciality": "Orthopaedics",
    "Sub Speciality": "Adult Reconstructive Orthopaedics"
  },
  {
    "Speciality": "Orthopaedics",
    "Sub Speciality": "Orthopaedic Sports Medicine"
  },
  {
    "Speciality": "Orthopaedics",
    "Sub Speciality": "Orthopaedics"
  },
  {
    "Speciality": "Orthopaedics",
    "Sub Speciality": "Pediatric Orthopaedics"
  },
  {
    "Speciality": "Otolaryngology",
    "Sub Speciality": "Otolaryngology"
  },
  {
    "Speciality": "Otolaryngology",
    "Sub Speciality": "Otology - Neurotology"
  },
  {
    "Speciality": "Otolaryngology",
    "Sub Speciality": "Pediatric Otolaryngology"
  },
  {
    "Speciality": "Otorhinolaryngology",
    "Sub Speciality": "Otorhinolaryngology"
  },
  {
    "Speciality": "Palliative Medicine",
    "Sub Speciality": "Palliative Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Adolescent Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Child Abuse Pediatrics"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Developmental-Behavioral Pediatrics"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Neonatal-Perinatal Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Neonatology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Cardiology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Critical Care Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Emergency Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Endocrinology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Gastroenterology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Hematology-Oncology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Paediatric Gastroenterology and Hepatology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Infectious Diseases"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Nephrology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Pulmonology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Rheumatology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Sports Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Transplant Hepatology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Allergy-Immunology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Cardiology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Otolaryngology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Radiology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Respiratory Medicine"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatric Urology"
  },
  {
    "Speciality": "Pediatrics",
    "Sub Speciality": "Pediatrics"
  },
  {
    "Speciality": "Physiology",
    "Sub Speciality": "Physiology"
  },
  {
    "Speciality": "Physiology",
    "Sub Speciality": "Electrophysiology"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Neuromuscular Medicine"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Pediatric Rehabilitation Medicine"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Physiotherapy"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Rehabilitation Medicine"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Spinal Cord Injury Medicine"
  },
  {
    "Speciality": "Physiotherapy & Rehabilitation",
    "Sub Speciality": "Paediatric Neuro Rehabilitation"
  },
  {
    "Speciality": "Preventive Medicine",
    "Sub Speciality": "Aerospace Medicine"
  },
  {
    "Speciality": "Preventive Medicine",
    "Sub Speciality": "Medical Toxicology"
  },
  {
    "Speciality": "Preventive Medicine",
    "Sub Speciality": "Preventive Medicine"
  },
  {
    "Speciality": "Preventive Medicine",
    "Sub Speciality": "Public Health Medicine"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Addiction Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Administrative Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Child and Adolescent Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Community Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Consultation/Liaison Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Emergency Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Forensic Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Geriatric Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Mental Retardation Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Military Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Psychiatry"
  },
  {
    "Speciality": "Psychiatry",
    "Sub Speciality": "Psychosomatic Medicine"
  },
  {
    "Speciality": "Psychology",
    "Sub Speciality": "Clinical Psychology"
  },
  {
    "Speciality": "Pulmonary Medicine",
    "Sub Speciality": "Pulmonary Medicine"
  },
  {
    "Speciality": "Respiratory & Sleep Medicine",
    "Sub Speciality": "Sleep Medicine"
  },
  {
    "Speciality": "Respiratory & Sleep Medicine",
    "Sub Speciality": "Respiratory medicine"
  },
  {
    "Speciality": "Rheumatology",
    "Sub Speciality": "Rheumatology"
  },
  {
    "Speciality": "Sexual & Reproductive Medicine",
    "Sub Speciality": "Sexual and Reproductive Medicine"
  },
  {
    "Speciality": "Sport & Exercise Medicine",
    "Sub Speciality": "Sport and Exercise Medicine"
  },
  {
    "Speciality": "Sport & Exercise Medicine",
    "Sub Speciality": "Yoga - Holistic Healing"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Bariatric Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Breast Cancer Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Cardio-thoracic surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Cardiovascular Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Colon and Rectal Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Congenital Cardiac Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Maxillofacial Surgery",
    "Classification": "Maxillofacial surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Endovascular Surgical Neuroradiology"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Gastrointestinal Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "General and Minimal Access Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "General Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Hand Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Head and Neck Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Hepato-Pancreatico-Biliary (HPB) Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Robotic Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Neurosurgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Surgical Oncology",
    "Classification": "Cancer specialist"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Gastrointestinal & Colorectal Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Oral and maxillofacial surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Podiatric Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Orthopaedics surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Paediatric surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Plastic & Reconstructive Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Plastic Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Thoracic Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Transplant Surgery",
    "Classification": "Liver transplant surgeon, Bowel transplant"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Thoracic Surgery-Integrated"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Upper GI Surgery"
  },
  {
    "Speciality": "Surgery",
    "Sub Speciality": "Vascular surgery"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Calculi",
    "Classification": "Stone specialist"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Female Urology"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Male Infertility"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Neurourology"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Pediatric Urology"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Renal Transplant",
    "Classification": "Transplant specialist -kidney"
  },
  {
    "Speciality": "Urology",
    "Sub Speciality": "Urology"
  }
]

const reducer = (acc, curr, idx) => {
  if (!(acc.speciality.includes(curr.Speciality))) {
    acc.speciality.push(curr.Speciality)
    acc.subSpeciality.push([curr["Sub Speciality"]])
    return acc
  }
  let subSpecIdx = acc.speciality.indexOf(curr.Speciality)
  acc.subSpeciality[subSpecIdx].push(curr["Sub Speciality"])
  return acc
}

const specialitySubUk = a.reduce(reducer, { speciality: [], subSpeciality: [] })

export default specialitySubUk