import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import specialitySubUk from './specialitySubUk'

class AutoCompleteSubSpecs extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      subSpecsTagList: []
    };
  }

  componentDidUpdate(prevProps) {
    // console.log("AutoCompleteSubSpecs::componentDidUpdate::this.props: ", this.props)
    if (prevProps.subSpecsTagList !== this.props.subSpecsTagList) {
      this.setState({
        subSpecsTagList: prevProps.subSpecsTagList
      })
    }
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    // this.props.updateSubSpecsTagList(this.props.subSpecsTagList.push(e.currentTarget.value))
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
    // this.props.updateSpecialityFromAuto(e.currentTarget.innerText)

    // console.log("AutoCompleteSubSpecs::onClick::this.props.subSpecsTagList: ", this.props.finalSubSpecs)
    // console.log("AutoCompleteSubSpecs::onClick::e.currentTarget.value: ", e.currentTarget.innerText)

    if (this.props.finalSubSpecs.indexOf(e.currentTarget.innerText) < 0) {
      this.props.updateFinalSubSpecs([...this.props.finalSubSpecs, e.currentTarget.innerText])
    }

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: ""

      // userInput: e.currentTarget.innerText
    });
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      e.preventDefault()

      // console.log("AutoCompleteSubSpecs::onKeyDown::this.props.subSpecsTagList: ", this.props.finalSubSpecs)
      console.log("AutoCompleteSubSpecs::onKeyDown::filteredSuggestions[activeSuggestion]: ", filteredSuggestions[activeSuggestion])
      console.log("AutoCompleteSubSpecs::onKeyDown::e.target: ", e.target.value)

      if (typeof filteredSuggestions[activeSuggestion] != "undefined" && this.props.finalSubSpecs.indexOf(filteredSuggestions[activeSuggestion]) < 0) {
        this.props.updateFinalSubSpecs([...this.props.finalSubSpecs, filteredSuggestions[activeSuggestion]])
      } else if (e.target.value !== "" && this.props.finalSubSpecs.indexOf(e.target.value) < 0) {
        this.props.updateFinalSubSpecs([...this.props.finalSubSpecs, e.target.value])
      }

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: ""
        // userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {

    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder="Sub Speciality"
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default AutoCompleteSubSpecs;
