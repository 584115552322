import React, { useState, useEffect } from 'react';

const RoraySelectedSubSpecs = (props) => {
  // const [tags, setTags] = useState([
  //   'Tags',
  //   'Input',
  //   'ada sdasd',
  //   'sdfsdf wer wer'
  // ]);

  const [propsState, setPropsState] = useState(props);

  const removeTag = (i) => {
    const newTags = [...propsState.finalSubSpecs];
    newTags.splice(i, 1);

    // Call the defined function setTags which will replace tags with the new value.
    propsState.updateFinalSubSpecs(newTags);
  };

  useEffect(() => {
    setPropsState(props);
  }, [props]);

  // console.log("RoraySelectedSubSpecs::props: ", propsState)
  return (
    <div className="input-tag" >
      <ul className="input-tag__tags">
        {propsState.finalSubSpecs.map((tag, i) => (
          <li key={tag}>
            {tag}
            <button type="button" onClick={() => { removeTag(i); }}>+</button>
          </li>
        ))}
        <li className="input-tag__tags__input"></li>
        {/* <li className="input-tag__tags__input"><input type="text" onKeyDown={inputKeyDown} ref={c => { tagInput = c; }} /></li> */}
      </ul>
    </div >
  );

}

export default RoraySelectedSubSpecs