import React, { useState } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import SavingSpinnerSvg from './SavingSpinnerSvg';
import SaveSuccessMessage from './SaveSuccessMessage'
import Autocomplete from './Autocomplete'
import AutoCompleteSubSpecs from './AutoCompleteSubSpecs'
import MandatoryStar from './MandatoryStar'
import specialitySubUk from './specialitySubUk'
import RoraySelectedSubSpecs from './RoraySelectedSubSpecs'
import Tnc from './Tnc'
import useModal from './useModal';

const axios = require('axios');

const getSubSpecsFromSelectedSpec = (selectedSpeciality) => {
  // console.log("selected sub spec inside getSubSpecsFromSelectedSpec", selectedSpeciality)
  let selectedSpecialityIndex = specialitySubUk.speciality.indexOf(selectedSpeciality)
  if (selectedSpecialityIndex >= 0) {
    return specialitySubUk.subSpeciality[selectedSpecialityIndex]
  }
  return [""]
}

export default function DoctorRegistration() {
  const { isShowing, toggle } = useModal();
  const { register, handleSubmit, errors } = useForm();
  const [saving, updateSaving] = useState(false);
  const [showSuccess, udpateShowSuccess] = useState(false)
  const [specialityFromAuto, updateSpecialityFromAuto] = useState("")
  const [subSpecialityFromAuto, updateSubSpecialityFromAuto] = useState("")
  const [finalSubSpecs, updateFinalSubSpecs] = useState([])

  const [tncAgreed, toggleTncAgreed] = useState(false)

  const onSubmit = async (data) => {
    updateSaving(!saving)

    try {

      let inputData = {
        "siteId": "register.cliniq.uk",
        "fName": data.firstName,
        "lName": data.lastName,
        "gender": data.gender,
        "email": data.email,
        "mobile": data.mobileNumber,
        "university": data.university,
        "qualification": data.qualifications,
        "yearOfQualification": data.degreeYear,
        "gmcNo": data.registrationId,
        "speciality": data.speciality,
        "subSpeciality": finalSubSpecs,
        "status": "NEW",
      }

      for (let [key, value] of Object.entries(inputData)) {
        // console.log(`!@!@ ${key}: ${value}`);
        if (value == "") delete inputData[key]
      }

      console.log(inputData)

      axios({
        method: 'post',
        url: 'https://h21en2knkl.execute-api.eu-west-2.amazonaws.com/default/clinivantage-cliniq-email-lambda',
        data: inputData
      })
        .then(function (response) {
          console.log("Success:: ", response.data);
          udpateShowSuccess(true)
        })
        .catch(function (error) {
          console.log("Error:: ", error);
        });

    }
    catch (e) {
      console.error(e)
    }
    finally {
      updateSaving(false)
    }

  }

  if (showSuccess) return <SaveSuccessMessage />

  return (

    <>
      <div style={{ textAlign: 'center', }}>
        <div style={{ position: "relative", top: "1em" }}>
          <img src="/CliniQLogo.png" style={{ width: "4em", margin: "auto", paddingTop: "1em" }} />
        </div>
        <h3 style={{ paddingTop: "1em", color: "#212F3D" }}>
          Doctor Registration Form
        </h3>
      </div>
      <div style={{ color: "#bf1650" }}><MandatoryStar />&nbsp;Note: All fields are required.</div>
      {!saving && <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset style={{ marginTop: 20 }}>
          <legend>Personal details:</legend>

          <label htmlFor="firstName">First name</label><MandatoryStar />
          <input type="text" placeholder="First name" name="firstName" ref={register({ required: "First name is required", maxLength: 100 })} />
          <ErrorMessage errors={errors} name="firstName" as="p" />

          <label htmlFor="lastName">Last name</label><MandatoryStar />
          <input type="text" placeholder="Last name" name="lastName" ref={register({ required: "Last name is required", maxLength: 100 })} />
          <ErrorMessage errors={errors} name="lastName" as="p" />

          <div style={{ display: "flex" }}>
            <label htmlFor="gender" style={{ marginRight: 20 }} style={{ display: "inline !important", marginRight: 20 }}>Gender<MandatoryStar /></label>
            <input
              name="gender"
              type="radio"
              value="male"
              ref={register({ required: "Please select your gender" })}
              style={{ display: "inline !important", marginTop: "26px", width: "auto" }}
            />
            <label
              style={{ display: "inline !important", marginRight: 20 }}
            >
              Male
            </label>
            <input
              name="gender"
              type="radio"
              value="female"
              ref={register({ required: "Please select your gender" })}
              style={{ display: "inline !important", marginTop: "26px", width: "auto" }}
            />
            <label
              style={{ marginRight: 20 }}
              style={{ display: "inline !important" }}

            >
              Female
            </label>
          </div>
          <ErrorMessage errors={errors} name="gender" as="p" />

          <label htmlFor="email">Email</label><MandatoryStar />
          <input type="email" placeholder="Email" name="email" ref={register({
            required: "Email is required", pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
              message: "Invalid email address"
            }
          })} />
          <ErrorMessage errors={errors} name="email" as="p" />

          <label htmlFor="mobileNumber">Mobile number</label><MandatoryStar />
          <div className="mobInput">
            <input type="tel" placeholder="Mobile number" name="mobileNumber" style={{ paddingLeft: '2em' }}
              ref={register({
                required: "Mobile number is required",
                maxLength: { value: 10, message: "Mobile number should be 10 digits" },
                minLength: { value: 10, message: "Mobile number should be 10 digits" },
                pattern: {
                  value: /^[0-9]*$/i,
                  // value: /^[0-9]*$/i,
                  message: 'Mobile number should be 10 digits'
                }
              })} />
          </div>
          <ErrorMessage errors={errors} name="mobileNumber" as="p" />
        </fieldset>

        <fieldset style={{ marginTop: 40 }}>
          <legend >Professional details:</legend>

          <label htmlFor="university">University</label><MandatoryStar />
          <input type="text" placeholder="University" name="university" ref={register({ required: "University is required" })} />
          <ErrorMessage errors={errors} name="university" as="p" />

          <label htmlFor="qualifications">Qualifications</label><MandatoryStar />
          <input type="text" placeholder="Qualifications" name="qualifications" ref={register({ required: "Qualifications details is required" })} />
          <ErrorMessage errors={errors} name="qualifications" as="p" />

          <label htmlFor="degreeYear">Year of Qualification</label><MandatoryStar />
          <input type="text" placeholder="Year of Qualification" name="degreeYear" ref={register({
            required: "Year of Qualification is required",
            maxLength: { value: 4, message: "Year of Qualification should be 4 digits" },
            minLength: { value: 4, message: "Year of Qualification should be 4 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Year of Qualification should be 4 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="degreeYear" as="p" />

          <label htmlFor="registrationId">GMC number</label>
          <input type="text" placeholder="GMC number" name="registrationId" ref={register} />

          <label htmlFor="speciality">Speciality</label>
          <input type="text" placeholder="Speciality" name="speciality" style={{ visibility: "hidden", height: 0, margin: 0, padding: 0 }} value={specialityFromAuto} ref={register} />
          <Autocomplete suggestions={specialitySubUk.speciality} updateSpecialityFromAuto={updateSpecialityFromAuto} />

          <label htmlFor="subSpeciality">Sub Speciality</label>
          <input type="text" placeholder="Sub Speciality" name="subSpeciality" style={{ visibility: "hidden", height: 0, margin: 0, padding: 0 }} value={subSpecialityFromAuto} />
          <AutoCompleteSubSpecs suggestions={getSubSpecsFromSelectedSpec(specialityFromAuto)} updateFinalSubSpecs={updateFinalSubSpecs} finalSubSpecs={finalSubSpecs} />

          {/* <SubSpecialityTagInput /> */}
          <RoraySelectedSubSpecs finalSubSpecs={finalSubSpecs} updateFinalSubSpecs={updateFinalSubSpecs} />

        </fieldset>

        <fieldset style={{ marginTop: '1em', border: "none" }}>
          <input type="checkbox" name="agreeTnc" ref={register} style={{ width: "auto", display: "inline" }} onClick={() => toggleTncAgreed(!tncAgreed)} />
          <label htmlFor="agreeTnc" style={{ width: "auto", display: "inline" }}> I agree to the <span onClick={toggle} style={{ color: "#2B867C", cursor: "pointer" }}><u>terms and conditions</u></span> of use</label>

          {/* <button className="button-default" onClick={toggle}>Show Modal</button> */}
          <Tnc
            isShowing={isShowing}
            hide={toggle}
          />

          <input type="submit" disabled={!tncAgreed} />
        </fieldset>
      </form>
      }

      {saving && <SavingSpinnerSvg />}
    </>
  );

}
